#maximized-img-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: zoom-out;
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    img {
      width: auto;
      height: auto;
      max-width: 95%;
      max-height: 95%;
    }

    picture{
        display: flex;
        justify-content: center;
        align-items: center;
    }
  }
  .close {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    padding: 1rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    opacity: 0.8;
    background-color: #222;
    color: #fff;

    &:hover {
      opacity: 1;
    }
  }
}
